/* Clouds */
#clouds {
  top: 200px;
  position: relative;
  animation: move 30s infinite linear;
  -webkit-animation: move 30s infinite linear;
  -moz-animation: move 30s infinite linear;
  -ms-animation: move 30s infinite linear;
  z-index: 1;
}

#clouds2 {
  position: relative;
  animation: backup 20s infinite linear;
  -webkit-animation: backup 20s infinite linear;
  -moz-animation: backup 20s infinite linear;
  -ms-animation: backup 20s infinite linear;
  z-index: 2;
}

.cloud1,
.cloud2,
.cloud3 {
  opacity: 1;
}

.cloud1 {
  width: 200px;
  height: 60px;
  background: white;
  position: absolute;
  top: 80px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  box-shadow: 0px 5px 20px 0.1px rgba(0, 0, 0, 0.2);
}

.cloud1:after {
  content: "";
  position: absolute;
  background: white;
  width: 80px;
  height: 80px;
  top: -40px;
  left: 20px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

.cloud1:before {
  content: "";
  position: absolute;
  background: white;
  width: 100px;
  height: 100px;
  top: -60px;
  right: 30px;

  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

.cloud2 {
  width: 100px;
  height: 30px;
  background: white;
  position: absolute;
  top: 180px;
  left: 400px;
  box-shadow: 0px 5px 20px 0.1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

.cloud2:after {
  content: "";
  position: absolute;
  background: white;
  width: 40px;
  height: 40px;
  top: -20px;
  left: 10px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

.cloud2:before {
  content: "";
  position: absolute;
  background: white;
  width: 50px;
  height: 50px;
  top: -30px;
  right: 15px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

.cloud3 {
  width: 200px;
  height: 60px;
  background: white;
  position: absolute;
  top: 100px;
  left: -740px;
  box-shadow: 0px 5px 20px 0.1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  color: #0abab5;
}

.cloud3:after {
  content: "";
  position: absolute;
  background: white;
  width: 80px;
  height: 80px;
  top: -40px;
  left: 20px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

.cloud3:before {
  content: "";
  position: absolute;
  background: white;
  width: 100px;
  height: 100px;
  top: -60px;
  right: 30px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

@keyframes move {
  0% {
    left: 0px;
  }
  49% {
    left: 1200px;
    opacity: 1;
  }
  50% {
    left: 1200px;
    opacity: 0;
  }
  51% {
    left: -1200px;
    opacity: 0;
  }
  52% {
    left: -1200px;
    opacity: 1;
  }
  100% {
    left: 0px;
  }
}
@-webkit-keyframes move {
  0% {
    left: 0px;
  }
  49% {
    left: 1200px;
    opacity: 1;
  }
  50% {
    left: 1200px;
    opacity: 0;
  }
  51% {
    left: -1200px;
    opacity: 0;
  }
  52% {
    left: -1200px;
    opacity: 1;
  }
  100% {
    left: 0px;
  }
}

@keyframes backup {
  0% {
    left: -1200px;
  }
  100% {
    left: 1200px;
  }
}
@-webkit-keyframes backup {
  0% {
    left: -1200px;
  }
  100% {
    left: 1200px;
  }
}

@-moz-keyframes move {
  0% {
    left: 0px;
  }
  49% {
    left: 1200px;
    opacity: 1;
  }
  50% {
    left: 1200px;
    opacity: 0;
  }
  51% {
    left: -1200px;
    opacity: 0;
  }
  52% {
    left: -1200px;
    opacity: 1;
  }
  100% {
    left: 0px;
  }
}

@-moz-keyframes backup {
  0% {
    left: -1200px;
  }
  100% {
    left: 1200px;
  }
}

@-ms-keyframes move {
  0% {
    left: 0px;
  }
  49% {
    left: 1200px;
    opacity: 1;
  }
  50% {
    left: 1200px;
    opacity: 0;
  }
  51% {
    left: -1200px;
    opacity: 0;
  }
  52% {
    left: -1200px;
    opacity: 1;
  }
  100% {
    left: 0px;
  }
}

@-ms-keyframes backup {
  0% {
    left: -1200px;
  }
  100% {
    left: 1200px;
  }
}
